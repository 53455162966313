<template>
  <div class="preloader" v-if="value">
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
