<template>
  <div :class="['chart-line-' + _uid]" class="pa-5 overflow-y-auto">
    <div
      v-show="tooltip.isActive"
      style="position: fixed; width: 200px"
      :style="{'left': tooltip.tooltipPositionX + 'px', 'top': tooltip.tooltipPositionY + 'px'}"
      class="chart-tooltip fz-13 fw-600 white--text one-line"
    >
      <div class="d-flex flex-column align-start">
        <template v-if="tooltip.data">
          <slot :data="tooltip.data"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import numberFormat from '@/helpers/numberFormat'

export default {
  name: 'ChartHistogram',
  filters: {
    numberFormat
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: true
    },
    valueY: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tooltip: {
        isActive: false,
        tooltipPositionX: 0,
        tooltipPositionY: 0,
        data: null
      }
    }
  },
  methods: {
    createChart(replace = false) {
      const vm = this
      /* eslint-disable */
      const chartSelector = `.chart-line-${this._uid}`
      const width = document.querySelector(chartSelector).clientWidth
      const height = 500
      const widthBar = 50
      const tooltipOffsetX = 20
      const tooltipOffsetY = 130

      if (replace) {
        document.querySelector(chartSelector + ' svg').remove()
      }

      document.querySelector(chartSelector).append(LineChart(JSON.parse(JSON.stringify(this.items)), {
        valueY: this.valueY
      }))

      function LineChart(data = [], options) {
        if (!data.length) return

        // Data sort/parse
        data.sort((a, b) => new Date(a.period) - new Date(b.period))

        /* const dates = [...data.map(el => new Date(el.period))].reverse()
        const value = [...data.map(el => el[options.valueY])].reverse() */

        data.forEach(item => {
          item.date = new Date(item.period)
          item[options.valueY] = Math.round(Number(item[options.valueY]))
        })

        const x = d3.map(data, d => d.date)
        const y = d3.map(data, d => d[options.valueY])

        // X Axis
        const xScale = d3.scaleTime()
          .domain([data[0].date, data[data.length - 1].date].reverse())
          .range([0, width - 40 - widthBar - widthBar - 11])
        const xAxis = d3.axisBottom(xScale)
          .ticks(data.length)
          .tickSize(0)
          .tickPadding(10)

        // Y Axis
        const yDomain = [0, d3.max(y)]
        const yScale = d3.scaleLinear()
          .domain(yDomain.reverse())
          .range([0, height])
        const yAxis = d3.axisLeft(yScale)
        yAxis.tickFormat(d => {
          const formattedNum = new Intl.NumberFormat('ru-RU').format(d)
          return options.valueY === 'amountRub' ? `${formattedNum}₽` : `${formattedNum}$`
        })

        /* yAxis.tickFormat(d => d, valueY === 'amountRub' ? '₽' : '$') */

        const heightMinEl = yScale(0) - yScale(d3.min(y))

        // Line
        /* const line = d3.line()
          .x(i => {
            return xScale(data[i].date)
          })
          .y(i => {
            return yScale(data[i].costRub)
          }) */

        // Creating SVG
        const svg = d3.create('svg')
          .attr('width', width - 40)
          .attr('height', 520)
          .attr('style', 'height: auto; height: intrinsic; font-family: Inter, sans-serif;')

        svg.append('g')
          .attr('class', 'chart-line-x-axis')
          .attr('transform', `translate(${widthBar + 11}, ${height})`)
          .call(xAxis)
          .selectAll('text')
          .attr('transform', `translate(${widthBar / 2}, 0)`)
          .attr('font-family', `Inter, sans-serif;`)

        svg.append('g')
          .attr('class', 'chart-line-y-axis')
          .attr('transform', `translate(60, 0)`)
          .call(yAxis)

        const backgroundBar = svg.append('g')
          .attr('fill', '#f7f7f7')
          .attr('transform', `translate(${ widthBar + 11}, 0)`)
          .selectAll('rect')
          .data(data)
          .join('rect')
          .attr('x', d => xScale(d.date))
          .attr('y', d => 0)
          .attr('height', height)
          .attr('width', widthBar)
          .on('mouseover', mouseover)
          .on('mouseleave', mouseleave)

        const bar = svg.append('g')
          .attr('fill', '#7552cc')
          .attr('transform', `translate(${widthBar + 11}, 0)`)
          .selectAll('rect')
          .data(data)
          .join('rect')
          .attr('x', d => xScale(d.date))
          .attr('y', d => yScale(Number(d[options.valueY])))
          .attr('height', d => yScale(0) - yScale(Number(d[options.valueY])))
          .attr('width', widthBar)
          .on('mouseover', mouseover)
          .on('mouseleave', mouseleave)

        function mouseover(event) {
          const el = d3.select(this)
          vm.tooltip.isActive = true
          vm.tooltip.tooltipPositionX = this.getBoundingClientRect().x
          /* event.offsetX + tooltipOffsetX */
          vm.tooltip.tooltipPositionY = this.getBoundingClientRect().y - tooltipOffsetY
          /* event.offsetY - tooltipOffsetY */
          vm.tooltip.data = el._groups[0][0].__data__
        }

        function mouseleave() {
          vm.tooltip.isActive = false
        }
/*
        /* svg.append('g')
          .attr('transform', `translate(${ marginLeft },0)`)
          .call(yAxis)
          .call(g => g.select('.domain').remove())
          .call(g => g.selectAll('.tick line').clone()
            .attr('x2', width - marginLeft - marginRight)
            .attr('stroke-opacity', 0.1))
          .call(g => g.append('text')
            .attr('x', -marginLeft)
            .attr('y', 10)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'start')
            .text(yLabel));

        svg.append('path')
          .attr('fill', 'none')
          .attr('transform', `translate(60, 0)`)
          .attr('stroke', 'red')
          .attr('stroke-width', 1.5)
          .attr("stroke-linecap", 'round')
          .attr("stroke-linejoin", 'round')
          .attr('d', line(d3.range(data.length)))
            */

        return svg.node();
      }
    }
  },
  mounted() {
    this.createChart()
  },
  watch: {
    loading: {
      handler(newVal) {
        console.log(newVal, 'newVal')
        if (newVal === false) this.createChart()
      }
    },
    items: {
      handler() {
        console.log('items changes chart')
        this.createChart(true)
      }
    }
  }
}
</script>

<style scoped>

</style>
