<template>
  <div class="width-100 pa-6">
    <v-row
      no-gutters
      class="my-2 flex-grow-0"
      :class="{'mx-2': $vuetify.breakpoint.smAndUp, 'mx-0': $vuetify.breakpoint.xsOnly, 'flex-column': $vuetify.breakpoint.xsOnly, 'align-start': $vuetify.breakpoint.xsOnly}"
    >
      <v-col cols="12" class="mb-4 d-flex">
        <v-btn-toggle
          class="rounded-lg height-30 box-shadow interval black--text hidden"
          style="padding-left: 2px; padding-right: 2px"
          :class="{'mr-5': $vuetify.breakpoint.smAndUp}"
          background-color="#F2F4F5"
          v-model="datePicker.interval"
          borderless
          dense>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getWeek"
            height="26">
            Week
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getMonth"
            height="26">
            Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getThreeMonth"
            height="26">
            3 months
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getYear"
            height="26">
            {{ getYearNow }} year
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getAllTime"
            height="26">
            All time
          </v-btn>
        </v-btn-toggle>
        <v-btn
          color="#010820"
          class="rounded-lg fz-13 fw-600 text-none mr-5"
          :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
          height="30"
          @click="showDatepicker"
          outlined>
          {{ datePicker.dateStart && datePicker.dateEnd ? 'Period: ' + datePicker.dateStart + ' - ' + datePicker.dateEnd : 'Specify period' }}
          <div @click.stop>
            <v-icon size="16" v-if="datePicker.dateStart && datePicker.dateEnd" class="ml-2" @click="getAllTime">mdi-close</v-icon>
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="header"
      :items="items.data"
      :loading="loading"
      :custom-sort="(items) => items"
      :disable-sort="loading"
      :options="{
        sortBy: ['paymentsRub'],
        sortDesc: [true]
      }"
      :server-items-length="items.totalItems"
      :footer-props="footerOptions"
      disable-filtering
      @update:options="customSort"
    >
      <template v-slot:header.name>
        <v-text-field
          v-model="debouncedInputValue"
          prepend-inner-icon="$search"
          placeholder="Name"
          outlined
          clearable
          dense
          @input="debouncedInput"
          @click.stop
          class="table-search d-inline-block ma-0 input-clear-small"
        />
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:item.ordersCount="{ item }">
        {{ item.ordersCount }}
      </template>
      <template v-slot:item.paymentsRub="{ item }">
        {{ Math.round(item.paymentsRub) | numberFormat }}₽
      </template>
      <template v-slot:item.ordersSumRub="{ item }">
        {{ Math.round(item.ordersSumRub) | numberFormat }}₽
      </template>
      <template v-slot:item.ordersLtv="{ item }">
        {{ Math.round(item.ordersLtv) | numberFormat }}₽
      </template>
      <template v-slot:item.ordersMinCostRub="{ item }">
        {{ Math.round(item.ordersMinCostRub) | numberFormat }}₽
      </template>
      <template v-slot:item.ordersAvgCostRub="{ item }">
        {{ Math.round(item.ordersAvgCostRub) | numberFormat }}₽
      </template>
      <template v-slot:item.ordersMaxCostRub="{ item }">
        {{ Math.round(item.ordersMaxCostRub) | numberFormat }}₽
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'StatisticTable',
  filters: {
    numberFormat
  },
  props: {
    items: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      datePicker: {
        interval: 4,
        dateStart: null,
        dateEnd: null
      },
      nameFilter: '',
      debouncedInputValue: '',
      sortType: 'PAYMENTS_DESC',
      pageOptions: {
        page: 1,
        pageSize: 10
      },
      footerOptions: {
        disablePagination: false,
        disableItemsPerPage: false
      },
      header: [
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Count',
          value: 'ordersCount',
          align: 'center'
        },
        {
          text: 'Payments',
          value: 'paymentsRub',
          align: 'center'
        },
        {
          text: 'Sum',
          value: 'ordersSumRub',
          align: 'center'
        },
        {
          text: 'LTV',
          value: 'ordersLtv',
          align: 'center'
        },
        {
          text: 'Min Cost',
          value: 'ordersMinCostRub',
          align: 'center'
        },
        {
          text: 'Avg Cost',
          value: 'ordersAvgCostRub',
          align: 'center'
        },
        {
          text: 'Max Cost',
          value: 'ordersMaxCostRub',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    getYearNow () {
      const date = new Date()
      return date.getFullYear()
    }
  },
  methods: {
    getWeek () {
      const end = new Date()
      const start = new Date()
      start.setDate(start.getDate() - 7)
      end.setDate(end.getDate() + 1)
      this.datePicker.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.datePicker.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 0
      }, 0)
    },
    getMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      end.setDate(end.getDate() + 1)
      this.datePicker.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.datePicker.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 1
      }, 0)
    },
    getThreeMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 3)
      end.setDate(end.getDate() + 1)
      this.datePicker.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.datePicker.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 2
      }, 0)
    },
    getYear () {
      this.datePicker.dateStart = this.getYearNow + '-01-01'
      this.datePicker.dateEnd = this.getYearNow + '-12-31'
      const vm = this
      setTimeout(function () {
        vm.interval = 3
      }, 0)
    },
    getAllTime () {
      this.datePicker.dateStart = null
      this.datePicker.dateEnd = null
      const vm = this
      setTimeout(function () {
        vm.interval = 4
      }, 0)
    },
    showDatepicker () {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerShow.click()
      }, 0)
    },
    customSort(data) {
      if (data.sortBy[0] === undefined) data.sortBy[0] = 'paymentsRub'
      const obj = {
        paymentsRub: 'PAYMENTS',
        ordersSumRub: 'ORDERS_SUM',
        ordersLtv: 'LTV',
        ordersMinCostRub: 'ORDERS_MIN_COST',
        ordersAvgCostRub: 'ORDERS_AVG_COST',
        ordersMaxCostRub: 'ORDERS_MAX_COST',
        ordersCount: 'ORDERS'
      }
      const sortBy = obj[Object.keys(obj).find(key => data.sortBy[0] === key)]
      this.sortType = data.sortDesc[0] ? `${sortBy}_DESC` : `${sortBy}_ASC`
      this.pageOptions.page = data.page
      this.pageOptions.pageSize = data.itemsPerPage !== -1 ? data.itemsPerPage : this.items.totalItems
      this.$emit('update:loading', true)
      this.emit()
    },
    debouncedInput: debounce(function () {
      this.nameFilter = this.debouncedInputValue
      this.emit()
    }, 1000),
    emit() {
      this.$emit('updateData', {
        name: this.nameFilter,
        sort: this.sortType,
        pageOptions: this.pageOptions,
        dateStart: this.datePicker.dateStart,
        dateEnd: this.datePicker.dateEnd
      })
    }
  },
  mounted() {
    this.getAllTime()
  },
  watch: {
    loading: {
      handler() {
        this.footerOptions.disablePagination = this.loading
        this.footerOptions.disableItemsPerPage = this.loading
      }
    },
    datePicker: {
      handler() {
        console.log('lsdkjf')
        this.emit()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
