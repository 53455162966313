<template>
  <div class="statistics pa-4 position-relative">
    <v-tabs
      v-model="tabs"
      class="tabs-link bb-gray flex-grow-0"
    >
      <v-tab>Dashboard</v-tab>
      <v-tab>Tables</v-tab>
      <v-tab :disabled="loadingValues.ordersDynamic && loadingValues.financeDynamic">Charts</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card
          width="32%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Orders</v-card-title>
          <v-card class="border-lightgray rounded-2">
            <Loader :value="loadingValues.totalOrder"/>
            <v-row no-gutters>
              <v-col>
                <v-card-title>
                  Total quantity
                </v-card-title>
                <v-card-text class="fz-16 font-weight-bold text-black">
                  <div class="mb-2">{{ totalOrderValues.totalCount }}</div>
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-title>
                  Total cost
                </v-card-title>
                <v-card-text class="fz-16 font-weight-bold text-black">
                  <div class="mb-2">{{ Math.round(totalOrderValues.totalCostRub) | numberFormat }}₽</div>
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-title>
                  Average cost
                </v-card-title>
                <v-card-text class="fz-16 font-weight-bold text-black">
                  <div class="mb-2">{{ Math.round(totalOrderValues.totalAvgCostRub) | numberFormat }}₽</div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Sales</v-card-title>
          <v-card class="border-lightgray rounded-2">
            <Loader :value="loadingValues.sales"/>
            <Calendar v-model="dates.sales" @changes="fetchSalesStatistics"/>
            <v-row no-gutters>
              <v-col>
                <v-card-title>
                  Expected Payments
                </v-card-title>
                <v-card-text class="fz-16 font-weight-bold text-black">
                  <div class="mb-2">{{ Math.round(salesStatistics.expectedPaymentsRub) | numberFormat }}₽</div>
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-title>
                  Order Sales
                </v-card-title>
                <v-card-text class="fz-16 font-weight-bold text-black">
                  <div class="mb-2">{{ Math.round(salesStatistics.orderSalesRub) | numberFormat }}₽</div>
                </v-card-text>
              </v-col>
              <v-col>
                <v-card-title>
                  Payments
                </v-card-title>
                <v-card-text class="fz-16 font-weight-bold text-black">
                  <div class="mb-2">{{ Math.round(salesStatistics.paymentsRub) | numberFormat }}₽</div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Plans & Pricing</v-card-title>
          <Loader :value="loadingValues.services"/>
          <Calendar v-model="dates.services" @changes="fetchServicesStatistics"/>
          <v-row no-gutters>
            <v-col class="mr-6">
              <v-card
                class="border-lightgray rounded-2"
              >
                <v-card-title>
                  Products
                </v-card-title>
                <ServicesTable type="Products" :items="products"/>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="border-lightgray rounded-2"
              >
                <v-card-title>
                  Services
                </v-card-title>
                <ServicesTable type="Services" :items="services"/>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4 d-flex justify-space-between"
        >
          <v-card
            width="100%"
            outlined
            class="border-none mb-4"
          >
            <v-card-title class="font-weight-bold">Comparison</v-card-title>
            <v-row
              no-gutters
              justify="space-between"
              align="start"
              class="width-100 flex-wrap"
            >
              <v-col class="mb-4">
                <v-card
                outlined
                class="border-none mb-4 mr-6"
              >
                  <v-card-title class="font-weight-bold">Designers</v-card-title>
                  <v-card class="border-lightgray rounded-2">
                    <Loader :value="loadingValues.designers"/>
                    <Calendar
                      v-model="dates.designers"
                      @changes="fetchDesignersComparison"
                    />
                    <v-row no-gutters>
                      <v-col>
                        <v-card-title>
                          Earned more
                        </v-card-title>
                        <ComparisonTable :items="designersComparison.desc" value="salary"/>
                      </v-col>
                      <v-col>
                        <v-card-title>
                          Earned Less
                        </v-card-title>
                        <ComparisonTable :items="designersComparison.asc" value="salary"/>
                      </v-col>
                    </v-row>
                    <!--<v-row
                      no-gutters
                      justify="center"
                      class="my-4"
                    >
                      <div class="font-weight-bold">
                        Show more
                      </div>
                    </v-row>-->
                  </v-card>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                  outlined
                  class="border-none"
                >
                  <v-card-title class="font-weight-bold">Orders</v-card-title>
                  <v-card class="border-lightgray rounded-2">
                    <Loader :value="loadingValues.comparisonOrder"/>
                    <Calendar
                      v-model="dates.orders"
                      @changes="fetchOrdersComparison"
                    />
                    <v-row no-gutters>
                      <v-col>
                        <v-card-title>
                          Cost more
                        </v-card-title>
                        <ComparisonTable :items="ordersComparison.desc" value="totalCostRub"/>
                      </v-col>
                      <v-col>
                        <v-card-title>
                          Cost less
                        </v-card-title>
                        <ComparisonTable :items="ordersComparison.asc" value="totalCostRub"/>
                      </v-col>
                    </v-row>
                    <!--<v-row
                      no-gutters
                      justify="center"
                      class="my-4"
                    >
                      <div class="font-weight-bold">
                        Show more
                      </div>
                    </v-row>-->
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Clients</v-card-title>
          <v-card class="border-lightgray rounded-2">
            <v-row no-gutters>
              <StatisticTable
                :items="clients"
                :loading="loadingValues.clients"
                @updateData="fetchClients"
              />
            </v-row>
          </v-card>
        </v-card>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Companies</v-card-title>
          <v-card class="border-lightgray rounded-2">
            <v-row no-gutters>
              <StatisticTable
                :items="companies"
                :loading="loadingValues.companies"
                @updateData="fetchCompanies"
              />
            </v-row>
          </v-card>
        </v-card>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Countries</v-card-title>
          <v-card class="border-lightgray rounded-2">
            <v-row no-gutters>
              <StatisticTable
                :items="countries"
                :loading="loadingValues.countries"
                @updateData="fetchCountries"
              />
            </v-row>
          </v-card>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Orders Dynamics</v-card-title>
          <v-card class="border-lightgray rounded-2">
            <ChartHistogram
              value-y="costRub"
              :items="ordersDynamic"
              :loading="loadingValues.ordersDynamic"
            >
              <template v-slot="{ data }">
                <div>
                  Period: {{ data.period }}
                </div>
                <div>
                  Cost: {{ Math.round(data.costRub) | numberFormat }}₽
                  <br>
                  Cost: ${{ Math.round(data.costUsd) | numberFormat }}
                </div>
                <div>
                  Avg: {{ Math.round(data.avgCostRub) | numberFormat }}₽
                  <br>
                  Avg: ${{ Math.round(data.avgCostUsd) | numberFormat }}
                </div>
                <div>
                  Count: {{ data.count }}
                </div>
              </template>
            </ChartHistogram>
          </v-card>
        </v-card>
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">
            Income Payments Dynamics
            <v-btn-toggle
              class="rounded-lg height-30 box-shadow interval black--text hidden ml-4"
              style="padding-left: 2px; padding-right: 2px; margin-right: 10px"
              background-color="#F2F4F5"
              borderless
              dense
              value="RUB"
              mandatory
              @change="changeCurrency"
            >
              <v-btn
                class="rounded-lg my-auto fz-13 text-none"
                plain
                height="26"
                value="USD"
              >
                USD
              </v-btn>
              <v-btn
                class="rounded-lg my-auto fz-13 text-none"
                plain
                height="26"
                value="RUB"
              >
                RUB
              </v-btn>
              <v-btn
                class="rounded-lg my-auto fz-13 text-none"
                plain
                height="26"
                value="BOTH"
              >
                Both
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-card class="border-lightgray rounded-2">
            <ChartHistogram
              :value-y="financeDataByCurrency.value"
              :items="financeDataByCurrency.items"
              :loading="loadingValues.financeDynamic"
            >
              <template v-slot="{ data }">
                <div>
                  Period: {{ data.period }}
                </div>
                <div>
                  Amount USD: ${{ Math.round(data.amountUsd) | numberFormat }}
                  <br>
                  Amount Rub: {{ Math.round(data.amountRub) | numberFormat }}₽
                </div>
                <div>
                  Count: {{ data.count }}
                </div>
              </template>
            </ChartHistogram>
          </v-card>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!--<v-row
      no-gutters
      class="my-2 flex-grow-0"
      :class="{'mx-2': $vuetify.breakpoint.smAndUp, 'mx-0': $vuetify.breakpoint.xsOnly, 'flex-column': $vuetify.breakpoint.xsOnly, 'align-start': $vuetify.breakpoint.xsOnly}"
    >
      &lt;!&ndash;Date&ndash;&gt;
      <v-col
        cols="12"
        class="mb-4 d-flex"
      >
        <v-btn-toggle
          class="rounded-lg height-30 box-shadow interval black&#45;&#45;text hidden"
          style="padding-left: 2px; padding-right: 2px"
          :class="{'mr-5': $vuetify.breakpoint.smAndUp}"
          background-color="#F2F4F5"
          borderless
          dense
        >
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            height="26"
          >
            Week
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            height="26"
          >
            Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            height="26"
          >
            3 months
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            height="26"
          >
            2023 year
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            height="26"
          >
            All time
          </v-btn>
        </v-btn-toggle>
        <v-btn
          color="#010820"
          class="rounded-lg fz-13 fw-600 text-none mr-5"
          :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
          height="30"
          outlined
        >
          da
        </v-btn>
      </v-col>
    </v-row>-->
    <!--<v-row
      no-gutters
      justify="space-between"
    >
      &lt;!&ndash;<v-card
        width="32%"
        outlined
        class="border-none mb-4"
      >
        <v-card-title class="font-weight-bold">Plans</v-card-title>
        <v-card class="border-lightgray rounded-2">
          <v-card-title>
            Sales
          </v-card-title>
          <v-card-text class="fz-16 font-weight-bold text-black position-relative">
            <div class="mb-2 text-right">US ${{ 2256 | numberFormat }} / US ${{ 3000 | numberFormat }}</div>
            &lt;!&ndash;<div>RUB {{ 2256 | numberFormat }}₽ / RUB {{ 4500 | numberFormat }}₽</div>&ndash;&gt;
            <ChartLinePlan
              selector="chart-sales"
              :data="[30, 150, 3000, 6000]"
            />
            &lt;!&ndash;<svg class="chart-sales" style="height: 14px; border-radius: 4px; width: 100%"></svg>
            <div
              style="position: absolute"
              :style="{'left': tooltipPositionX + 'px'}"
              class="tooltip fz-13 fw-600 white&#45;&#45;text one-line d-flex align-center"
            >
              Hi there
            </div>&ndash;&gt;
          </v-card-text>
          <v-divider/>
          <v-card-title>
            Income
          </v-card-title>
          <v-card-text class="fz-16 font-weight-bold text-black">
            <div class="mb-2 text-right">US ${{ 5000 | numberFormat }} / US ${{ 14035 | numberFormat }}</div>
            <ChartLinePlan
              selector="chart-income"
              :data="[5000, 14035]"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-title>Expected Income</v-card-title>
          <v-card-text class="fz-16 font-weight-bold text-black">
            <div class="mb-2 text-right">US ${{ 5000 | numberFormat }} / US ${{ 14035 | numberFormat }}</div>
            <svg
              class="chart-expected-income"
              style="height: 14px; border-radius: 4px; width: 100%"
            ></svg>
          </v-card-text>
        </v-card>
      </v-card>&ndash;&gt;
      &lt;!&ndash;<v-card
        width="32%"
        outlined
        class="border-none mb-4"
      >
        <v-card-title class="font-weight-bold">Services</v-card-title>
        <v-card class="border-lightgray rounded-2">
          <v-card-title>
            Top services
          </v-card-title>
          <v-card-text class="fz-16 font-weight-bold text-black py-1">
            <div>1. Niche Product Site - ${{ 41241 | numberFormat }}</div>
          </v-card-text>
          <v-card-text class="fz-16 font-weight-bold text-black py-1">
            <div>2. E-commerce - ${{ 3123 | numberFormat }}</div>
          </v-card-text>
          <v-card-text class="fz-16 font-weight-bold text-black py-1">
            <div>3. Test - ${{ 21 | numberFormat }}</div>
          </v-card-text>
          <v-card-text class="fz-16 text-black py-1">
            <div>4. Second Test - ${{ 4 | numberFormat }}</div>
          </v-card-text>
          <v-card-text class="fz-16 text-black py-1">
            <div>5. Third Test - ${{ 4 | numberFormat }}</div>
          </v-card-text>
          <v-card-text class="fz-16 text-black py-3">
            <div class="font-weight-bold text-center">Show more</div>
          </v-card-text>
        </v-card>
      </v-card>&ndash;&gt;
      &lt;!&ndash;<v-card
        width="100%"
        outlined
        class="border-none mb-4 d-flex justify-space-between"
      >
        <v-card
          width="100%"
          outlined
          class="border-none mb-4"
        >
          <v-card-title class="font-weight-bold">Comparison</v-card-title>
          <v-row
            no-gutters
            justify="space-between"
            align="start"
            class="width-100"
          >
            <v-card
              width="40%"
              outlined
              class="border-none mb-4"
            >
              <v-card-title class="font-weight-bold">Designers</v-card-title>
              <v-card class="border-lightgray rounded-2">
                <v-row no-gutters>
                  <v-col>
                    <v-card-title>
                      Earned more
                    </v-card-title>
                    <v-card-text
                      v-for="(designer, index) in designersComparison"
                      :key="designer.designer.id"
                      class="fz-16 text-black py-1"
                    >
                      {{ index + 1 }}. {{ designer.designer.businessName }} - {{ Math.round(designer.salary) | numberFormat }}₽
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-card-title>
                      Earned Less
                    </v-card-title>
                    <div class="d-flex flex-column-reverse">
                      <v-card-text
                        v-for="(designer, index) in designersComparison"
                        :key="designer.designer.id + '-reversed'"
                        class="fz-16 text-black py-1"
                      >
                        {{ designersComparison.length - index }}. {{ designer.designer.businessName }} - {{ Math.round(designer.salary) | numberFormat }}₽
                      </v-card-text>
                    </div>
                  </v-col>
                </v-row>
                &lt;!&ndash;<v-row
                  no-gutters
                  justify="center"
                  class="my-4"
                >
                  <div class="font-weight-bold">
                    Show more
                  </div>
                </v-row>&ndash;&gt;
              </v-card>
            </v-card>
            <v-card
              width="40%"
              outlined
              class="border-none mb-4"
            >
              <v-card-title class="font-weight-bold">Orders</v-card-title>
              <v-card class="border-lightgray rounded-2">
                <v-row no-gutters>
                  <v-col>
                    <v-card-title>
                      Cost more
                    </v-card-title>
                    <v-card-text
                      v-for="(order, index) in ordersComparison.desc"
                      :key="order.id"
                      class="fz-16 text-black py-1"
                    >
                      {{ index + 1 }}. {{ order.name }} - {{ Math.round(order.totalCostRub) | numberFormat }}₽
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-card-title>
                      Cost less
                    </v-card-title>
                    <v-card-text
                      v-for="(order, index) in ordersComparison.asc"
                      :key="order.id"
                      class="fz-16 text-black py-1"
                    >
                      {{ index + 1 }}. {{ order.name }} - {{ Math.round(order.totalCostRub) | numberFormat }}₽
                    </v-card-text>
                  </v-col>
                </v-row>
                &lt;!&ndash;<v-row
                  no-gutters
                  justify="center"
                  class="my-4"
                >
                  <div class="font-weight-bold">
                    Show more
                  </div>
                </v-row>&ndash;&gt;
              </v-card>
            </v-card>
          </v-row>
        </v-card>
      </v-card>&ndash;&gt;
    </v-row>-->
  </div>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
/* import ChartLinePlan from '@/components/App/charts/ChartLinePlan' */
import Calendar from '@/components/App/Statistics/Calendar'
import StatisticTable from '@/components/App/Statistics/StatisticTable'
import ComparisonTable from '@/components/App/Statistics/ComparisonTable'
import ServicesTable from '@/components/App/Statistics/ServicesTable'
import ChartHistogram from '@/components/App/charts/ChartHistogram'
import Loader from '@/components/App/UI/Loader'
import gql from 'graphql-tag'
import { mapMutations } from 'vuex'

export default {
  name: 'StatisticsPage',
  filters: {
    numberFormat
  },
  components: {
    ComparisonTable,
    Loader,
    Calendar,
    StatisticTable,
    ServicesTable,
    /* ChartLinePlan, */
    ChartHistogram
  },
  data() {
    return {
      dates: {
        sales: {
          dateStart: null,
          dateEnd: null
        },
        services: {
          dateStart: null,
          dateEnd: null
        },
        designers: {
          dateStart: '2018-01-01',
          dateEnd: '2099-01-01'
        },
        orders: {
          dateStart: null,
          dateEnd: null
        }
      },
      tabs: [],
      loadingValues: {
        totalOrder: false,
        comparisonOrder: false,
        sales: false,
        services: false,
        designers: false,
        clients: false,
        companies: false,
        countries: false,
        ordersDynamic: false,
        financeDynamic: false
      },
      tooltip: true,
      tooltipPositionX: 0,
      tooltipPositionY: 0,
      filters: {
        clients: null,
        companies: null,
        countries: null
      },
      clients: {},
      companies: {},
      countries: {},
      ordersDynamic: [],
      finance: [],
      financeDataByCurrency: {
        value: 'amountRub',
        items: []
      },
      designersComparison: {
        desc: [],
        asc: []
      },
      ordersComparison: {
        desc: [],
        asc: []
      },
      totalOrderValues: [],
      products: [],
      services: [],
      salesStatistics: []
    }
  },
  methods: {
    ...mapMutations(['showSnackbar']),
    changeCurrency(e) {
      let currency
      let data
      switch (e) {
        case 'USD': {
          currency = 'amountUsd'
          data = 'usdPaymentsByMonth'
          break
        }
        case 'RUB': {
          currency = 'amountRub'
          data = 'rubPaymentsByMonth'
          break
        }
        case 'BOTH': {
          currency = 'amountRub'
          data = 'paymentsByMonth'
          break
        }
      }
      this.financeDataByCurrency = {
        value: currency,
        items: this.finance[data]
      }
    },
    async fetchOrdersDynamic() {
      this.loadingValues.ordersDynamic = true
      await this.$apollo.query({
        query: gql`
        query fetchOrdersDynamic {
          orders {
            ordersSalesByMonth {
              avgCostRub
              avgCostUsd
              costRub
              costUsd
              count
              period
            }
          }
        }
          `,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.ordersDynamic = data.data.orders.ordersSalesByMonth
        this.loadingValues.ordersDynamic = false
      }).catch((err) => {
        this.loadingValues.ordersDynamic = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchPayments() {
      this.loadingValues.financeDynamic = true
      await this.$apollo.query({
        query: gql`
        query fetchPayments {
          finance {
            paymentsByMonth {
              amountRub
              amountUsd
              period
              count
            }
            rubPaymentsByMonth {
              amountRub
              amountUsd
              period
              count
            }
            usdPaymentsByMonth {
              amountRub
              amountUsd
              count
              period
            }
            rubPaymentsByYear {
              amountRub
              amountUsd
              count
              period
              totalAmountRub
              totalAmountUsd
              totalCount
            }
            usdPaymentsByYear {
              amountRub
              amountUsd
              count
              period
              totalAmountRub
              totalAmountUsd
              totalCount
            }
          }
        }
          `,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.finance = data.data.finance
        this.financeDataByCurrency = {
          value: 'amountRub',
          items: this.finance.rubPaymentsByMonth
        }
        this.loadingValues.financeDynamic = false
      }).catch((err) => {
        this.loadingValues.financeDynamic = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchClients(data) {
      this.loadingValues.clients = true
      await this.$apollo.query({
        query: gql`
        query statisticsClients($periodEnd: datetime, $periodStart: datetime, $orderBy: [ClientStatisticsEnum!] = PAYMENTS_DESC, $searchName: String, $page: Int, $pageSize: Int) {
            clientele {
              paginatedClientsStatistics(
                input: {periodStart: $periodStart, periodEnd: $periodEnd, orderBy: $orderBy, searchName: $searchName, page: $page, pageSize: $pageSize}
              ) {
                totalPages
                totalItems
                data {
                  id
                  paymentsUsd
                  paymentsRub
                  ordersSumUsd
                  ordersSumRub
                  ordersMinCostUsd
                  ordersMinCostRub
                  ordersMaxCostUsd
                  ordersMaxCostRub
                  ordersLtv
                  ordersCount
                  ordersAvgCostUsd
                  ordersAvgCostRub
                  initials
                  name
                }
              }
            }
          }
          `,
        variables: {
          periodStart: data ? data.dateStart : null,
          periodEnd: data ? data.dateEnd : null,
          orderBy: data ? data.sort : 'PAYMENTS_DESC',
          searchName: data ? data.name : null,
          page: data ? data.pageOptions.page : 1,
          pageSize: data ? data.pageOptions.pageSize : 10
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.clients = data.data.clientele.paginatedClientsStatistics
        this.loadingValues.clients = false
      }).catch((err) => {
        this.loadingValues.clients = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchCompanies(data) {
      this.loadingValues.companies = true
      await this.$apollo.query({
        query: gql`
        query statisticsCompany($periodEnd: datetime, $periodStart: datetime, $orderBy: [CompanyOrderingEnum!] = PAYMENTS_DESC, $name: String, $page: Int, $pageSize: Int) {
          clientele {
            __typename
            paginatedCompanies(
              input: {periodStart: $periodStart, periodEnd: $periodEnd, orderBy: $orderBy, name: $name, page: $page, pageSize: $pageSize}
            ) {
              totalPages
              totalItems
              data {
                id
                name
                ordersAvgCostRub
                ordersAvgCostUsd
                ordersCount
                ordersLtv
                ordersMaxCostRub
                ordersMaxCostUsd
                ordersMinCostRub
                ordersMinCostUsd
                ordersSumRub
                ordersSumUsd
                paymentsRub
                paymentsUsd
              }
            }
          }
        }
          `,
        variables: {
          periodStart: data ? data.dateStart : null,
          periodEnd: data ? data.dateEnd : null,
          orderBy: data ? data.sort : 'PAYMENTS_DESC',
          name: data ? data.name : null,
          page: data ? data.pageOptions.page : 1,
          pageSize: data ? data.pageOptions.pageSize : 10
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.companies = data.data.clientele.paginatedCompanies
        this.loadingValues.companies = false
      }).catch((err) => {
        this.loadingValues.companies = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchCountries(data) {
      this.loadingValues.countries = true
      await this.$apollo.query({
        query: gql`
          query statisticsCountries($periodEnd: datetime, $periodStart: datetime, $orderBy: [CountryEnum!] = PAYMENTS_DESC, $name: String, $page: Int, $pageSize: Int) {
            clientele {
              __typename
              paginatedCountries(
                input: {periodEnd: $periodEnd, periodStart: $periodStart,, orderBy: $orderBy, name: $name, page: $page, pageSize: $pageSize}
              ) {
                totalPages
                totalItems
                data {
                  id
                  name
                  ordersAvgCostRub
                  ordersAvgCostUsd
                  ordersCount
                  ordersMaxCostRub
                  ordersMaxCostUsd
                  ordersMinCostUsd
                  ordersMinCostRub
                  ordersSumRub
                  ordersSumUsd
                  paymentsRub
                  paymentsUsd
                  ordersLtv
                }
              }
            }
          }
          `,
        variables: {
          periodStart: data ? data.dateStart : null,
          periodEnd: data ? data.dateEnd : null,
          orderBy: data ? data.sort : 'PAYMENTS_DESC',
          name: data ? data.name : null,
          page: data ? data.pageOptions.page : 1,
          pageSize: data ? data.pageOptions.pageSize : 10
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.countries = data.data.clientele.paginatedCountries
        this.loadingValues.countries = false
      }).catch((err) => {
        this.loadingValues.countries = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchDesignersComparison() {
      if (this.dates.designers.dateStart === null) {
        this.dates.designers.dateStart = '2018-01-01'
        this.dates.designers.dateEnd = '2099-01-01'
      }
      this.loadingValues.designers = true
      await this.$apollo.query({
        query: gql`
          query getDesignersComparison($input: DesignerSalaryFilterInput!) {
            team {
              designersSalary(input: $input) {
                designersSalary {
                  salary
                  currency
                  designer {
                    id
                    businessName
                  }
                }
              }
            }
          }
          `,
        variables: {
          input: {
            roleId: null,
            periodStart: this.dates.designers ? this.dates.designers.dateStart : '2018-01-01',
            periodEnd: this.dates.designers ? this.dates.designers.dateEnd : '2099-01-01'
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const response = data.data.team.designersSalary.designersSalary
        response.forEach(el => {
          el.name = el.designer.businessName
        })
        this.designersComparison.desc = response
        this.designersComparison.asc = response.reverse()
        this.loadingValues.designers = false
      }).catch((err) => {
        this.loadingValues.designers = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchOrdersComparison() {
      this.loadingValues.comparisonOrder = true
      await this.$apollo.query({
        query: gql`
          query getOrdersComparison($dateEnd: date, $dateStart: date) {
            orders {
              desc: paginatedOrders(
                input: {orderBy: FINAL_COST_RUB_DESC, dateEnd: $dateEnd, dateStart: $dateStart}
              ) {
                data {
                  id
                  name
                  totalCost
                  totalCostRub
                  totalCostUsd
                  __typename
                }
                __typename
              }
              asc: paginatedOrders(
                input: {orderBy: FINAL_COST_RUB_ASC, dateEnd: $dateEnd, dateStart: $dateStart}
              ) {
                data {
                  id
                  name
                  totalCost
                  totalCostRub
                  totalCostUsd
                  __typename
                }
                __typename
              }
              __typename
            }
          }
          `,
        variables: {
          dateStart: this.dates.orders ? this.dates.orders.dateStart : null,
          dateEnd: this.dates.orders ? this.dates.orders.dateEnd : null
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.ordersComparison.desc = data.data.orders.desc.data
        this.ordersComparison.asc = data.data.orders.asc.data
        this.loadingValues.comparisonOrder = false
      }).catch((err) => {
        this.loadingValues.comparisonOrder = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchTotalOrderValue() {
      this.loadingValues.totalOrder = true
      await this.$apollo.query({
        query: gql`
          query getTotalOrderValues{
            orders {
              ordersSalesByYear {
                totalAvgCostRub
                totalAvgCostUsd
                totalCostRub
                totalCostUsd
                totalCount
              }
            }
          }
          `,
        variables: {
          input: {
            periodStart: '2015-01-01',
            periodEnd: '2099-10-01',
            roleId: null
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.totalOrderValues = data.data.orders.ordersSalesByYear[0]
        this.loadingValues.totalOrder = false
      }).catch((err) => {
        this.loadingValues.totalOrder = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchServicesStatistics() {
      this.loadingValues.services = true
      await this.$apollo.query({
        query: gql`
          query getServicesStatistics($periodEnd: datetime, $periodStart: datetime, $reverse: Boolean, $sortBy: SortField) {
            orders {
              products: productPlanStatistics(
                input: {sortBy: $sortBy, reverse: $reverse, periodStart: $periodStart, periodEnd: $periodEnd}
              ) {
                productPlans {
                  count
                  totalCostRub
                  totalCostUsd
                  avgPagesCount
                  item: productPlan {
                    id
                    name
                  }
                }
              }
              services: serviceStatistics(
                input: {sortBy: $sortBy, reverse: $reverse, periodStart: $periodStart, periodEnd: $periodEnd}
              ) {
                services {
                  count
                  totalCostRub
                  totalCostUsd
                  item: service {
                    id
                    name
                  }
                }
              }
            }
          }
          `,
        variables: {
          sortBy: 'COUNT',
          reverse: true,
          periodStart: this.dates.services ? this.dates.services.dateStart : null,
          periodEnd: this.dates.services ? this.dates.services.dateEnd : null
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.products = data.data.orders.products.productPlans
        this.services = data.data.orders.services.services
        this.loadingValues.services = false
      }).catch((err) => {
        this.loadingValues.services = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    },
    async fetchSalesStatistics() {
      this.loadingValues.sales = true
      await this.$apollo.query({
        query: gql`
          query getSalesStatistics($periodEnd: datetime, $periodStart: datetime) {
            finance {
              salesStatistics(input: {periodEnd: $periodEnd, periodStart: $periodStart}) {
                expectedPaymentsRub
                expectedPaymentsUsd
                orderSalesRub
                orderSalesUsd
                paymentsRub
                paymentsUsd
              }
            }
          }
          `,
        variables: {
          periodStart: this.dates.sales ? this.dates.sales.dateStart : null,
          periodEnd: this.dates.sales ? this.dates.sales.dateEnd : null
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.salesStatistics = data.data.finance.salesStatistics
        this.loadingValues.sales = false
      }).catch((err) => {
        this.loadingValues.sales = false
        this.showSnackbar(['Error', err])
        console.log(err)
      })
    }
  },
  mounted() {
    this.fetchOrdersDynamic()
    this.fetchPayments()
    this.fetchClients()
    this.fetchCompanies()
    this.fetchCountries()
    this.fetchDesignersComparison()
    this.fetchOrdersComparison()
    this.fetchTotalOrderValue()
    this.fetchServicesStatistics()
    this.fetchSalesStatistics()
  }
}
</script>

<style
  scoped
  lang="scss"
>

</style>
