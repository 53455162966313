<template>
  <v-row
    no-gutters
    class="my-2 flex-grow-0"
    :class="{'mx-2': $vuetify.breakpoint.smAndUp, 'mx-0': $vuetify.breakpoint.xsOnly, 'flex-column': $vuetify.breakpoint.xsOnly, 'align-start': $vuetify.breakpoint.xsOnly}"
  >
    <v-col cols="12" class="d-flex">
      <v-btn-toggle
        class="rounded-lg height-30 box-shadow interval black--text hidden"
        style="padding-left: 2px; padding-right: 2px"
        :class="{'mr-5': $vuetify.breakpoint.smAndUp}"
        background-color="#F2F4F5"
        v-model="datePicker.interval"
        borderless
        dense>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getWeek"
          height="26">
          Week
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getMonth"
          height="26">
          Month
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getThreeMonth"
          height="26">
          3 months
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getYear"
          height="26">
          {{ getYearNow }} year
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getAllTime"
          height="26">
          All time
        </v-btn>
      </v-btn-toggle>
      <v-btn
        color="#010820"
        class="rounded-lg fz-13 fw-600 text-none mr-5"
        :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
        height="30"
        @click="showDatepicker"
        outlined>
        {{ value.dateStart && value.dateEnd ? 'Period: ' + value.dateStart + ' - ' + value.dateEnd : 'Specify period' }}
        <div @click.stop>
          <v-icon size="16" v-if="value.dateStart && value.dateEnd" class="ml-2" @click="getAllTime">mdi-close</v-icon>
        </div>
      </v-btn>
    </v-col>
    <vc-date-picker
      class="inline-block h-full"
      locale="en"
      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
      :popover="{ visibility: 'click' }"
      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      mode="range"
      is-range
      @popoverWillShow="overlay = true"
      @popoverDidHide="overlay = false"
      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
      :value="fakeValue"
      @input="setDateManually"
    >
      <template v-slot="">
        <button
          ref="datepickerShow"
          style="display: none"
        ></button>
      </template>
    </vc-date-picker>
    <v-overlay
      :z-index="5"
      :value="overlay"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
    />
  </v-row>
</template>

<script>
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'Calendar',
  props: {
    value: {
      type: Object,
      default: () => ({
        dateStart: '',
        dateEnd: ''
      })
    }
  },
  data() {
    return {
      overlay: false,
      datePicker: {
        interval: 4
      },
      fakeValue: ''
    }
  },
  computed: {
    getYearNow () {
      const date = new Date()
      return date.getFullYear()
    }
  },
  methods: {
    setDateManually(e) {
      console.log('dsf')
      this.value.dateStart = locale.format(e.start, 'YYYY-MM-DD')
      this.value.dateEnd = locale.format(e.end, 'YYYY-MM-DD')
    },
    getWeek () {
      const end = new Date()
      const start = new Date()
      start.setDate(start.getDate() - 7)
      end.setDate(end.getDate() + 1)
      this.setDate(start, end, 0)
    },
    getMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      end.setDate(end.getDate() + 1)
      this.setDate(start, end, 1)
    },
    getThreeMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 3)
      end.setDate(end.getDate() + 1)
      this.setDate(start, end, 2)
    },
    getYear () {
      const start = this.getYearNow + '-01-01'
      const end = this.getYearNow + '-12-31'
      this.setDate(start, end, 3)
    },
    getAllTime () {
      this.setDate(null, null, 4)
    },
    showDatepicker () {
      setTimeout(() => {
        this.$refs.datepickerShow.click()
      })
    },
    setDate(start, end, interval) {
      if (start === null && end === null) {
        this.value.dateStart = start
        this.value.dateEnd = end
      } else {
        this.value.dateStart = locale.format(start, 'YYYY-MM-DD')
        this.value.dateEnd = locale.format(end, 'YYYY-MM-DD')
      }
      setTimeout(() => {
        this.interval = interval
      })
      this.$emit('changes')
    }
  },
  mounted() {
    this.getAllTime()
  }
}
</script>

<style scoped>

</style>
