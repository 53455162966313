import { render, staticRenderFns } from "./StatisticTable.vue?vue&type=template&id=fdd6af44&scoped=true&"
import script from "./StatisticTable.vue?vue&type=script&lang=js&"
export * from "./StatisticTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdd6af44",
  null
  
)

export default component.exports