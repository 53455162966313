<template>
  <v-data-table
    :headers="type === 'Products' ? headerProducts : headerServices"
    :items="items"
  >
    <template v-slot:item.name="{ item }">
      {{ item.item.name }}
    </template>
    <template v-slot:item.count="{ item }">
      {{ item.count }}
    </template>
    <template v-slot:item.avgPagesCount="{ item }">
      {{ item.avgPagesCount }}
    </template>
    <template v-slot:item.totalCostUsd="{ item }">
      ${{ Math.round(item.totalCostUsd) | numberFormat }}
    </template>
    <template v-slot:item.totalCostRub="{ item }">
      {{ Math.round(item.totalCostRub) | numberFormat }}₽
    </template>
  </v-data-table>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'

export default {
  name: 'ServicesTable',
  filters: {
    numberFormat
  },
  props: {
    type: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      headerProducts: [
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Count',
          value: 'count',
          align: 'center'
        },
        {
          text: 'Avg Count',
          value: 'avgPagesCount',
          align: 'center'
        },
        {
          text: 'Total USD',
          value: 'totalCostUsd',
          align: 'center'
        },
        {
          text: 'Total RUB',
          value: 'totalCostRub',
          align: 'center'
        }
      ],
      headerServices: [
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Count',
          value: 'count',
          align: 'center'
        },
        {
          text: 'Total USD',
          value: 'totalCostUsd',
          align: 'center'
        },
        {
          text: 'Total RUB',
          value: 'totalCostRub',
          align: 'center'
        }
      ]
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
