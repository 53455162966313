<template>
  <v-data-table
    :headers="header"
    :items="items"
    :items-per-page="-1"
    hide-default-footer
  >
    <template v-slot:item.name="{ item }">
      {{ item.name }}
    </template>
    <template v-slot:item.value="{ item }">
      {{ Math.round(item[value]) | numberFormat }}₽
    </template>
  </v-data-table>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
export default {
  name: 'ComparisonTable',
  filters: {
    numberFormat
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: '',
      required: true
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      header: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Value',
          value: 'value'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
